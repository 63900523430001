<template>
  <div>
    <div class="row mx-2 my-0">
      <div
        class="
          col-12 col-md-10 col-lg-8
          offset-md-1 offset-lg-2
          card
          theme-card
          border-0
          py-5
          px-4
        "
      >
        <h1 class="mb-5">Add New Referral Source</h1>
        <form @submit.prevent="createReferralSource" validate>
          <input-control
            type="horizontal"
            v-model.trim="referralSource.first"
            labelClass="col-md-3 col-lg-2"
            required
            >First Name</input-control
          >
          <input-control
            type="horizontal"
            v-model.trim="referralSource.last"
            labelClass="col-md-3 col-lg-2"
            required
            >Last Name</input-control
          >
          <save classes="mt-3 save-button btn btn-theme" :saving="saving"
            >Save</save
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      referralSource: {
        first: "",
        last: "",
      },
    };
  },
  computed: {
    ...mapState({
      saving: (state) => state.referralSources.saving,
    }),
  },
  methods: {
    ...mapActions({
      create: "referralSources/create",
    }),
    createReferralSource: function () {
      this.create(this.referralSource);
    },
  },
};
</script>
